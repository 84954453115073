@font-face {
  font-family: 'Muli';
  src: url('./fonts/muli/Muli-Regular.ttf');
}

body {
  margin: 0;
  font-family: 'Muli', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #444;
  font-size: 1rem; /* 16px */
  line-height: 1.5; /* 24px */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#chatsvg {
  color: black;
}

/* override user-agent default */
input {
  font-family: 'Muli';
  color: #444;
  font-size: 1rem;
}

div,
input,
ul,
li {
  box-sizing: border-box;
}
